
require("./../node_modules/@module-federation/nextjs-mf/src/include-defaults.js");
import Home from '@pages/home'
import Head from 'next/head'

export default function Index() {
  return (
    <div>
      <Head>
        <title>A2B Tommyfield Cars</title>
        <meta name="description" content="App" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Home />
    </div>
  )
}
