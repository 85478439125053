export const ClientTestimonialData = [
    {
        id: 1,
        title: 'VERY GOOD TAXI SERVICE',
        quote: 'Very good taxi service, always on time and the staff are really nice and easy to talk to. will definitely use them again and I recommend that everybody else does too. BEST TAXI SERVICE IN OLDHAM!!!!!!',
        name: 'Emma wilson',
        designation: 'customer',
        rating: 5,
        img_src: '/assets/images/client-dp-female.png',
        img_alt: 'Picture of the author'
    },
    {
        id: 2,
        title: 'BRILLIANT SERVICES',
        quote: "I absolutely LOVE A2B Taxis, they never fail to let me down and are always there when I need them! They're FAB! Great prices and a brilliant service!",
        name: 'Emily Jayne',
        designation: 'customer',
        rating: 5,
        img_src: '/assets/images/client-dp-female.png',
        img_alt: 'Picture of the author'
    },
    {
        id: 3,
        title: 'ALL PERFECT!',
        quote: "Cheap prices quick driving freshie drivers all perfect",
        name: 'Graciano Luvunga',
        designation: 'customer',
        rating: 5,
        img_src: '/assets/images/client-dp-male.png',
        img_alt: 'Picture of the author'
    },
]