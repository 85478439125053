import { appstorelinks } from '@components/config/AppStoreLinks';
import { company } from '@components/config/Company';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import React from 'react'
import { ourServices, servicesData } from './data';
import DoneIcon from '@mui/icons-material/Done';
import ClientTestimonial from '@pages/common/ClientTestimonial';
const Booker = dynamic(() => import('main/bookerWrapper'), { ssr: false });
function Home() {
    return (
        <>
            <div className='bg-homeBg bg-cover  bg-no-repeat  flex justify-end items-center py-4'>
                <div className="lg:w-[40%] h-[800px] w-[100%] z-10">
                    <Booker
                        secretId={process.env.NEXT_PUBLIC_UID}
                        qrId={process.env.NEXT_PUBLIC_QR_ID}
                        iframe
                    />
                </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-4 gap-8 px-4 md:px-32 my-10'>
                {
                    servicesData.map((service) => (
                        <div key={service.id} className=' '>
                            <div className=' flex items-center justify-center mb-10'>
                                <Image src={service.image_url} alt={service.image_alt} height={110} width={110} />
                            </div>
                            <div className=''>
                                <h1 className='text-2xl text-center mb-2'>{service.title}</h1>
                                <p className='text-justify text-black/70 text-sm'>{service.description}</p>
                            </div>
                        </div>
                    ))

                }
            </div>

            <div className='bg-red py-10 md:min-h-[450px] flex md:items-end flex-col relative px-2 md:pl-20 '>
                <div className='absolute md:block hidden left-80 top-72'>
                    <Image src='/assets/images/mobile-car.png' alt='phone' height={136} width={249} />
                </div>
                <div className='absolute md:block hidden left-24 top-14'>
                    <Image src='/assets/images/mobile.png' alt='phone' height={458} width={230} />
                </div>
                <div className='md:w-[60%]'>
                    <div className='text-textYellow text-3xl text-left'>Download the App</div>
                    <div className='text-white font-thin text-md'>
                        Want to save time and money? Just simply download our app today. Its easy to use and is available to download for free on Play store.
                    </div>
                    <div className='mt-5'>
                        {
                            appstorelinks.map((link) => (
                                <a key={link.alt} href={link.href} target='_blank' rel='noreferrer'>
                                    <Image
                                        src={link.Imgsrc}
                                        alt={link.alt}
                                        height={59}
                                        width={175}
                                    />
                                </a>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className='md:pt-40 my-10 px-2 md:px-32 grid grid-cols-1 md:grid-cols-2 gap-10'>
                <div>
                    <div className='text-2xl mb-2'>About</div>
                    <div className='text-red text-3xl mb-2'>A2B Tommyfield Cars</div>
                    <div className='text-black/60 text-md text-justify'>
                        Welcome to A2B Tommyfield Cars where our customers benefit from over 100 vehicles that are available to transport you 24/7 anywhere in the UK. We have introduced an affordable fare for our customers and ever since been rewarded with many loyal customers. We can provide a vehicle to suit any journey. Our large fleet allows us to supply prestige & executive vehicles for special occasions to standard cars that will get you from A to B. We also have a range of Airport taxis to take you from or to any airport in the UK.
                    </div>
                    <div className='flex my-3'>
                        <div className='cursor-pointer bg-red/95 hover:bg-red px-3 py-1 text-white rounded'>
                            Learn More
                        </div>
                    </div>
                </div>
                <div>
                    <div className='text-2xl mb-2'>About</div>
                    <div className='text-red text-3xl font-semibold mb-2'>Our Services</div>
                    {
                        ourServices.map((service, index) => (
                            <div key={index} className='flex items-center mb-3'>
                                <DoneIcon className='' />
                                <div className='text-black text-md ml-2'>{service}</div>
                            </div>
                        ))
                    }
                    <div className='flex my-3'>
                        <div className='cursor-pointer bg-red/95 hover:bg-red px-3 py-1 text-white rounded'>
                            Learn More
                        </div>
                    </div>
                </div>
            </div>
            <ClientTestimonial />
            <div className='bg-red px-2 md:px-32 py-7'>
                <div className='text-textYellow text-xl md:text-2xl mb-2'>Call us to book a taxi</div>
                <div className='md:text-5xl text-3xl font-semibold text-white mb-2'>{company.phone}</div>
                <div className='text-white font-semibold text-xl md:text-2xl'>The operator will call back immediately and report the cost of travel.</div>
            </div>
        </>
    )
}

export default Home