export const appstorelinks = [
  {
    Imgsrc: '/assets/images/googleplay-store-btn.png',
    href: 'https://play.google.com/store/apps/details?id=com.icabbi.a2btommyfieldcars&hl=en',
    alt: 'Android App Store Icon',
  },
  // {
  //   Imgsrc: '/assets/images/appleStore.png',
  //   href: 'http://onelink.to/lynx?&dev=other',
  //   alt: 'Apple Store Icon',
  // },
];
