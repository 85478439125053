import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Image from 'next/image';
import StarIcon from '@mui/icons-material/Star';
import { ClientTestimonialData } from './data';
function ClientTestimonial() {
    const Stars = (rating) => {
        let stars = [];
        for (let i = 0; i < rating; i++) {
            stars.push(<StarIcon key={i} className='text-textYellow' />)
        }
        return stars;
    }
    return (
        <div>
            <div className='text-center  text-2xl'>Client</div>
            <div className='text-center mt-1 mb-7 text-3xl text-red font-bold'>Testimonial</div>

            <Carousel showIndicators={false} showStatus={false} showThumbs={false} className='md:mx-20 mx-2   '>
                {
                    ClientTestimonialData.map((item) => {
                        return (
                            <div key={item.id} className='md:py-10 py-20'>
                                <div className='border-red border-2 rounded py-2 md:px-4 '>
                                    <div className='flex items-center gap-3'>
                                        <div className='bg-red -mt-10'>
                                            <FormatQuoteIcon className='text-7xl text-white' />
                                        </div>
                                        <div className='text-lg font-semibold'>
                                            {item.title}
                                        </div>
                                    </div>
                                    <div className='md:text-left text-center my-5 text-black/70'>
                                        {item.quote}
                                    </div>
                                    <div className='flex items-center justify-center md:items-start md:justify-start -mb-20  md:-mb-10'>
                                        <div className='bg-white border-red border-2 md:rounded-full p-2 md:flex md:items-center md:gap-2'>
                                            <Image
                                                src={item.img_src}
                                                alt={item.img_alt}
                                                width={66}
                                                className='rounded-full'
                                                height={66}
                                            />
                                            <div className='font-semibold text-sm'>
                                                {item.name}
                                            </div>
                                            <div className=' text-sm'>
                                                - {item.designation}
                                            </div>
                                            <div className='flex '>
                                                {Stars(item.rating)}
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        )

                    }
                    )
                }


            </Carousel>
        </div>
    )
}

export default ClientTestimonial