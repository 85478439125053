export const servicesData = [
    {
        id: 1,
        title: 'Airport Transfers',
        description: 'A2B Tommyfield Cars provide a fast and friendly service to the airport at an affordable price. We travel to and from any airport around the country. We also have a range of vehicles from mini bus to prestige so you can now ride in comfort. Our service is 24 hours a day 7 days a week. Need that holiday? No problem we got you',
        image_url: '/assets/images/airport.png',
        image_alt: 'Airport Transfers',
    },
    {
        id: 2,
        title: 'Competitive Pricing',
        description: 'Looking for a cheap rate taxi? Sounds like you need to call us. A2B Tommyfield Cars are a well established firm with over 30 years of experience around the Oldham and Manchester area. We offer a fixed fare service for long journeys so you know exactly how much you pay before you start your journey.',
        image_url: '/assets/images/compettive.png',
        image_alt: 'Competitive Pricing',
    },
    {
        id: 3,
        title: 'Timely & Safe',
        description: 'Get to places you want to travel to with us as we offer a prompt and punctual service in and around the Oldham area. Once booked our drivers will reach the pick up location in 3-4 minutes. You can also download our app. Book a taxi in seconds and see exactly where your car is and get an exact Eta.',
        image_url: '/assets/images/timely.png',
        image_alt: 'Timely & Safe',
    },
    {
        id: 4,
        title: 'Best Services',
        description: 'We strive to provide the best service around oldham. Our aim is to always be on time for our customers as getting late can cause alot of problems. We have cars scattered all around the oldham area so we can get cars to our customers within minutes.',
        image_url: '/assets/images/best-services.png',
        image_alt: 'Best Services',
    }
]

export const ourServices = [
    '100% Customer-Satisfaction',
    'No Hidden Charges, No Extra Costs',
    'Fixed Prices',
    ' Fast & Safe Airport Transfers',
    'Nationwide',
    'Secure Online & App Booking',
    'Learn More',
]